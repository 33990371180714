import React, { useEffect, useState, useRef } from 'react';
import NSK from '../Assets/nsk.jpg';
import SW from '../Assets/sternweber.png';
import MyRay from '../Assets/myray.jpg';
import Faro from '../Assets/faro.png';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import axios from 'axios';

const Categories = ({ onSearchChange, category, subcategory, setCurrentPage }) => {

    const API = process.env.REACT_APP_BACKEND;
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState([]);
    const [toggleMenuId, setToggleMenuId] = useState(null);

    const [companylogo,setCompanylogo] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 600);
        };
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    const handleSearchChange = (e) => {
        const newValue = e.target.value;
        setSearch(newValue);
        onSearchChange(newValue); // Pass the value to parent
    };

    useEffect(() => {
        const logos = {
          nsk: NSK,
          "stern_weber": SW,
          myray: MyRay,
          faro: Faro,
        };
        setCompanylogo(logos[category] || null);
      }, [category]);


    const formatNameForURL = (name) => {
        return name
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/\//g, "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      };

    useEffect(() => {
        const fetchCategories = async () => {
          try {
            const response = await axios.get(`${API}/categories`);
            const transformedData = response.data.map((category) => ({
              ...category,
              subcategories: category.subcategories ? category.subcategories.split(",") : [],
            }));
            setCategories(transformedData);
          } catch (error) {
            console.error(error);
          }
        };
        fetchCategories();
      }, []);

    const toggleMenu = (categoryId) => {
        setToggleMenuId((prevId) => (prevId === categoryId ? null : categoryId));
      };
    
      // Handle navigation
      const handleCategoryClick = (categoryName) => {
        setIsOpen(false);
        const formattedCategory=formatNameForURL(categoryName);
        setCurrentPage(1);
        navigate(`/${formattedCategory}`);
      };
    
      const handleSubcategoryClick = (categoryName, subcategoryName) => {
        setIsOpen(false);
        setCurrentPage(1);
        const formattedCategory=formatNameForURL(categoryName);
        const formattedSubCategory=formatNameForURL(subcategoryName)
        navigate(`/${formattedCategory}/${formattedSubCategory}`);
      };
  return (
    <div className='category-container'>
        {companylogo && (
          <img src={companylogo} className='brand-logo' alt='product-image'/>
        )}
        <button
        className="category-toggle"
        aria-label={isOpen ? "Close Menu" : "Open Menu"}
        onClick={() => setIsOpen(!isOpen)}
        >
        Szűrő
        </button>
        <div className={`category-items ${isOpen ? 'visible' : 'hidden'}`}>
          <input
            type="text"
            placeholder="Keresés...."
            value={search}
            onChange={handleSearchChange}
          />
          {categories.length > 0 ? (
            categories.map((category) => (
              <div key={category.id} className="category">
                <div className="category-name">
                  <h2 onClick={() => handleCategoryClick(category.category_name)}>
                    {category.category_name}
                  </h2>
                  {category.subcategories.length > 0 && (
                    <button onClick={() => toggleMenu(category.id)}>
                      {toggleMenuId === category.id ? <FaArrowUp /> : <FaArrowDown />}
                    </button>
                  )}
                </div>
                <div className="category-subcategory">
                  {toggleMenuId === category.id && (
                    <ul>
                      {category.subcategories.map((subcategory, index) => (
                        <li
                          key={index}
                          onClick={() => handleSubcategoryClick(category.category_name, subcategory)}
                        >
                          {subcategory}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No categories available</p>
          )}
        </div>
    </div>
  )
}

export default Categories