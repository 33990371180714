import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate} from 'react-router';
import '../css/Item.css';
import LoadingIcons from 'react-loading-icons';
import ReactPlayer from "react-player";
import SubProductsCard from '../Components/SubProductsCard';


const Item = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = location.state || {};
    const [product, setProduct] = useState(null);
    const [subProducts,setSubProducts] = useState(null);

    const handleDownloadPdf = () => {
        if (product && product.pdf) {
            const blob = new Blob(
                [Uint8Array.from(atob(product.pdf.split(",")[1]), (c) => c.charCodeAt(0))],
                { type: product.pdf_content_type || "application/pdf" }
            );
            const url = URL.createObjectURL(blob);

            // Trigger the download
            const link = document.createElement("a");
            link.href = url;
            link.download = `${product.name || "product"}.pdf`;
            link.click();

            // Clean up the URL object
            URL.revokeObjectURL(url);
        }
    };

    useEffect(() => {
        const API = process.env.REACT_APP_BACKEND;
        
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${API}/products/${id}`);
                setProduct(response.data);
                console.log(response.data)
            } catch (error) {
                console.error(error);
            }
        };
    
        const fetchSubProducts = async () => {
            try {
                const response = await axios.get(`${API}/subproducts/${id}`);
                console.log(response.data)
                setSubProducts(response.data);
            } catch (error) {
                console.log(error)
            }
        };

        fetchProduct();
        fetchSubProducts();
    },[id]);

    return (
        <div>
            <NavBar />
            {product ? (
                <div className='item'>
                    <div className='item-header'>
                        <img src={product.image} alt={product.name} />
                        <div>
                            <h1>{product.name}</h1>
                            <p>{product.brand}</p>
                            <p>{product.category}</p>
                            <p>{product.subcategory}</p>
                            <button
                                onClick={() =>
                                    navigate(`/kapcsolat?offertag=${encodeURIComponent(product.name)} - Ajánlatkérés`)
                                }
                            >
                                Ajánlatkérés
                            </button>
                        </div>
                    </div>
                    <div className='item-description'>
                        <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                        {product.hasPdf && (
                            <button onClick={handleDownloadPdf}>Download PDF</button>
                        ) }
                        {product.video && (
                            <ReactPlayer
                            url={product.video}
                            controls
                            width="100%"
                            height="360px"
                          />
                        )}
                    </div>
                    {subProducts &&
                        subProducts.map((item, index) => (
                            <SubProductsCard key={index} item={item} />
                        ))
                    }
                </div>
            ) : (
                <div className="loading-container">
                    <LoadingIcons.Bars stroke="#3256a8"/>
                </div>
            )}
            <Footer />
        </div>
    );
};
export default Item;