import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBar';
import Contact_Cards from '../Components/Contact_Cards';
import Footer from '../Components/Footer'
import '../css/Contact.css'
import { useNavigate, useSearchParams } from 'react-router';
import axios from 'axios';

const Contact = () => {
  const navigate=useNavigate();
  const [subject, setSubject] = useState('');
  const [searchParams] = useSearchParams();
  const [name,setName]= useState('');
  const [email,setEmail]= useState('');
  const [phone,setPhone]= useState('');
  const [message,setMessage]= useState('');
  const [status,setStatus]=useState('');

  useEffect(() => {
    const offerTag = searchParams.get("offertag");
    if (offerTag) {
        setSubject(offerTag);
    }
    window.scrollTo(0, 0);
    }, [searchParams])

    const handleSubmit= async ()=> {
      try {
        const response = await axios.post('http://192.168.0.7:8000/api/email', {name, email, subject, phone, message});
        console.log(response.data);
        setStatus("Email elküldve!");
        navigate('/koszonjuk');
      } catch (error) {
        console.log(error);
        setStatus("Email küldése sikertelen!");
      }
    }
  return (
    <div>
      <NavBar/>
      <div  className='contact'>
      <form className='contact-form' onSubmit={handleSubmit}>
        <label htmlFor="">Teljes Név</label>
        <input type="text" placeholder='Teljes Név' value={name} onChange={(e) => setName(e.target.value)}/>
        <label htmlFor="">Email</label>
        <input type="text" placeholder='példa@gmail.com' value={email} onChange={(e) => setEmail(e.target.value)}/>
        <label htmlFor="">Tárgy</label>
        <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Tárgy'/>
        <label htmlFor="">Telefonszám</label>
        <input type="text" placeholder='+36 ' value={phone} onChange={(e) => setPhone(e.target.value)}/>
        <label htmlFor="">Üzenet</label>
        <textarea className='ms' placeholder='üzenet'value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
        <button type='submit'>Küldés</button>
        <p>{status}</p>
      </form>
      <Contact_Cards/>
      <div className='info'>
      </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2708.8640552605934!2d16.645317076639934!3d47.23880497115837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476eb9852b7fda81%3A0x26fafedd16cec422!2zRGVudGFsY29vcCBQbHVzIEtmdC4tIEZvZ8Ohc3phdGkga2V6ZWzFkWVneXPDqWcsIHLDtm50Z2VuIMOpcnTDqWtlc8OtdMOpcyDDqXMgc3plcnZpeg!5e0!3m2!1shu!2shu!4v1733410714235!5m2!1shu!2shu" width="100%" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='location'></iframe>
      <Footer/>
    </div>
  )
}

export default Contact