import React from 'react'
import { useNavigate } from 'react-router';

const SubProductsCard = ({item}) => {
  
  const formatNameForURL = (name) => {
    return name
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/\//g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  };


  const handleCategoryClick = (category) => {
    const formattedCategory=formatNameForURL(category);
    navigate(`/${formattedCategory}`)

  }

  const handleSubcategoryClick = (category, subcategory) => {
    const formattedCategory=formatNameForURL(category);
    const formattedSubCategory=formatNameForURL(subcategory);
    navigate(`/${formattedCategory}/${formattedSubCategory}`);
  }

  const navigate=useNavigate();
  return (
    <div className='subproduct'>
        <div className='subproduct-title'>
            <h2>{item.name}</h2>
            <p onClick={() => handleCategoryClick(item.category)}>{item.category}</p>
            <p onClick={() => handleSubcategoryClick(item.category, item.subcategory)}>{item.subcategory}</p>
            <img src={item.image} alt={item.name} />
        </div>
        <div className='subproduct-detail'>
            <p dangerouslySetInnerHTML={{__html: item.description}}></p>
        </div>
    </div>
  )
}

export default SubProductsCard;