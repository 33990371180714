import React from 'react';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer'
import '../css/Services.css'
import infos from '../data/contacts.json';
import Service_Info_Cards from '../Components/Service_Info_Cards';

const Services = () => {
  return (
    <div>
      <NavBar/>
    <div className='services-main'>
      <div className='services'>
        <h1 className='title'>Szolgáltatások</h1>
        <div className="services-cards">
          <div className="services-card">
            <h2>Kézidarabok, Autoklávok, Implantációs<br/>Berendezések Javítása</h2>
            <p>Küldje el hozzánk kézidarabját és mi akár egy munkanap alatt javítva visszaküldjük!</p>
            <p>Autoklávok, implantációs berendezések javítása esetén, igény illetve kapacítás függvényében cserekészüléket biztosítunk!</p>
          </div>
          <div className="services-card">
            <h2>Időszakos Felülvizsgálatok</h2>
            <p>Orvostechnikai Eszközökre 4/2009(III. 17.) Eüm Rendelet alapján</p>
            <ul>
              <li>Fogászati kezelőegység</li>
              <li>Panoráma röntgen</li>
              <li>Intraorális röntgen</li>
              <li>Gőzsterilizátor, Hőlégsterilizátor</li>
              <li>Műtőlámpa</li>
            </ul>
            <p>Felülvizsgálatra időpontegyesztetés céljából kerresen minket elérhetőségeinken egyikén</p>
          </div>
        </div>
      </div>
      <h1>Szakképzett Kollegáink</h1>
      <div className='service-info-cards'>
        {infos.infos.map((item, index) => (<Service_Info_Cards 
        key={index} 
        state={item.state} 
        name={item.name} 
        address={item.address} 
        phone={item.phone}
        />))}
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Services