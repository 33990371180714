import React from 'react'
import '../css/Footer.css'
import { Link } from 'react-router'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-info'>
        <div>
          <h2>Nyitvatartás</h2>
          <p>Hétfő-Csütörtök 09:00-16:30</p>
          <p>Péntek 09:00-15:00</p>
        </div>
        <div>
          <h2>Elérhetőségek</h2>
          <p>9700 Szombathely, Sárvár u. 12</p>
          <p>info@Dentalcoopplus.hu</p>
          <p>+36 20 956 5480</p>
        </div>
        <div className='footer-info-files'>
          <h2>Információk</h2>
          <div>
            <a href="/files/adatkezelesi.pdf" target="_blank" rel="noopener noreferrer">Adatkezelési tájékoztató</a>
          </div>
          <div>
            <Link to={'/impressum'}>Impresszum</Link>
          </div>
          <div>
            <a href="/files/aszf.pdf" target="_blank" rel="noopener noreferrer">Ászf</a>
          </div>
        </div>
      </div>
      <div>
       <p>® Minden jog fenntartva - Dentalcoopplus Kft. - 2006-2025</p>
      </div>
    </div>
  )
}

export default Footer