import React from 'react';
import NavBar from './Components/NavBar';
import Header from './Components/Header';
import Products_Component from './Components/Products_Component';
import Services_Component from './Components/Services_Component';
import Footer from './Components/Footer';
import Companies from './Components/Companies';
import ClientsWidget from './Components/ClientsWidget';
import FadeIn from './Components/FadeIn';

const Main = () => {
  return (
    <div className='main'>
        <NavBar/>
        <FadeIn>
        <Header/>
        </FadeIn>
        <FadeIn>
        <Products_Component/>
        </FadeIn>
        <FadeIn>
        <ClientsWidget/>
        </FadeIn>
        <FadeIn>
        <Services_Component/>
        </FadeIn>
        <Companies/>
        <Footer/>
    </div>
  )
}

export default Main