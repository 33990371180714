import React from "react";
import './App.css';
import {Routes, Route} from 'react-router';
import Main from './Main';
import Contact from './Sites/Contact';
import Thank from './Sites/Thank';
import Products from './Sites/Products';
import Services from './Sites/Services';
import WebshopMain from './Webshop/WebshopMain';
import About from './Sites/About';
import Item from './Sites/Item';
import Impressum from './Sites/Impressum';
import { initializeGTM } from "./gtm";

initializeGTM();

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/kezdolap' element={<Main/>}/>
        <Route path='/kapcsolat' element={<Contact/>}/>
        <Route path='/koszonjuk' element={<Thank/>}/>
        <Route path='/:category/:subcategory/:name' element={<Item/>}/>
        <Route path='/' element={<Products/>}/>
        <Route path='/:category' element={<Products/>}/>
        <Route path='/:category/:subcategory' element={<Products/>}/>
        <Route path='/szolgaltatas' element={<Services/>}/>
        <Route path='/rolunk' element={<About/>}/>
        <Route path='/webshop' element={<WebshopMain/>}/>
        <Route path='/impressum' element={<Impressum/>}/>
      </Routes>
    </div>
  );
}

export default App;
