import '../css/Products.css'
import { useNavigate } from 'react-router'

const ProductComponent = ({item}) => {
  const navigate=useNavigate();
  const formatNameForURL = (name) => {
    return name
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/\//g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  };

  const handleClick = (categoryName,subcategoryName, name, id) => {
    const formattedCategory=formatNameForURL(categoryName)
    const formattedSubCategory=formatNameForURL(subcategoryName)
    const formattedName=formatNameForURL(name)
    navigate(`/${formattedCategory}/${formattedSubCategory}/${formattedName}`,{
      state: {id}
    })
  }
  return (
    <div className='product-card' onClick={() => handleClick(item.category, item.subcategory, item.name, item.id)}>
            <img src={item.image} alt={item.name}/>
            <div className='product-card-details'>
              <h2>{item.name}</h2>
              <p>{item.brand}</p>
            </div>
    </div>
  )
}

export default ProductComponent