import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import {FaPhoneAlt} from "react-icons/fa";

const Service_Info_Cards = ({state, name, address, phone}) => {
  return (
    <div className='service-info-card'>
        <div className='service-info-card-title'>
            <FaLocationDot />
            <h1>{state} Megye</h1>
        </div>
        <div className='service-infos-card-detail'>
            <p>{name}</p>
            <p>{address}</p>
        </div>
        <div className='service-infos-card-phone'>
            <FaPhoneAlt/>
            <a href={`tel:${phone}`}>{phone}</a>
        </div>
    </div>
  )
}

export default Service_Info_Cards