import React from "react";
import Background from '../Assets/hero_background.jpg';
import { useNavigate } from "react-router";
import '../css/Hero.css'

const Header = () => {
    const navigate=useNavigate();

  return (
    <div className="hero">
        <img src={Background} alt="" />
        <div className="hero-title">
            <h1>
                Látogass el a megújúlt<br/>
                WebShopunkra
            </h1>
            <button onClick={() => navigate('/webshop')}>Webshop</button>
        </div>
    </div>
  );
};

export default Header;