import React from "react";
import data from "../data/data.json";
import '../css/Products_Component.css'
import { useNavigate } from "react-router";

const Products_Component = () => {
    const navigate=useNavigate();

    const slides = data.headerItems.map((item, index) => (
        <div key={index} className="card">
          <img src={item.image} alt={item.title} />
          <div className="details">
            <h1>{item.title}</h1>
            <button onClick={() => navigate(`/kapcsolat?offertag=${encodeURIComponent(item.title)} - Ajánlatkérés`)}>Ajánlatkérés</button>
          </div>
        </div>
      ));
    
      return (
        <div className="products_c">
            <h1>Kiemelt Termékek</h1>
            <div className="cards">
            {slides}
            </div>
        </div>
      );
}

export default Products_Component