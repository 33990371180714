import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NSK from '../Assets/nsk.jpg';
import SW from '../Assets/sternweber.png';
import Cattani from '../Assets/cattani.png';
import Nouvag from '../Assets/Nouvag.png';
import Bodeku from '../Assets/bodeku.jpg';
import TG from '../Assets/technogaz.jpeg';
import Faro from '../Assets/faro.png';
import Enbio from '../Assets/enbio.png';
import Zilfore from '../Assets/zilfor.jpg';
import MR from '../Assets/myray.jpg';
import '../css/Companies.css'

const Companies = () => {

const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const data = [
    NSK,
    SW,
    Cattani,
    Nouvag,
    Bodeku,
    TG,
    Faro,
    Enbio,
    Zilfore,
    MR
]

const small = isSmallScreen ? 3 : 6;

var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: small,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    arrows:false
  };
  
    return (
        <div className="carousel">
        <Slider {...settings}>
        {data.map((d, index) => (
            <div className='images' key={index}>
                <img src={d} alt={`Company ${index + 1}`} />
            </div>
        ))}
        </Slider>
        </div>
    );
}

export default Companies