import React from 'react'
import Counter from './Counter';

const ClientsWidget = () => {
  return (
    <div className='counter'>
        <div><h2>Elégedett Ügyfelek</h2></div>
        <div className='counter-number'><Counter targetNumber={2000} />+</div>
    </div>
  )
}

export default ClientsWidget