import React from 'react'
import Logo from '../Assets/logo.png'
import './WebshopMain.css'

const WebshopMain = () => {
  return (
    <div className='webshop'>
      <img src={Logo} alt="" />
      <h1>Fejlesztés folyamatban...</h1>
      </div>
  )
}

export default WebshopMain