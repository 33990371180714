import React, { useEffect } from 'react'
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import { useNavigate } from 'react-router';

const Thank = () => {
  const navigate=useNavigate();
  
  useEffect(()=> {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "conversion",
      send_to: "AW-878940797/wZB8CN-wvIcaEP2kjqMD",
      value: 1.0,
      currency: "HUF",
    });
  },[])
  
  return (
    <div>
        <NavBar/>
        <div className='thank-you-page'>
            <div className='thank-you-page-card'>
            <h1>Köszönjük!</h1>
            <h2>Üzenetét megkaptuk!</h2>
            </div>
            <div className='thank-you-page-back'>
              <button onClick={() => navigate('/products')}>Vissza a termékekhez</button>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Thank