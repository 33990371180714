import React from 'react'
import Footer from '../Components/Footer';
import NavBar from '../Components/NavBar';
import '../css/Impressum.css'

const Impressum = () => {
  return (
    <div>
        <NavBar/>
        <div className='impressum'>
            <h1>Impresszum</h1>
            <div className='impressum-item'>
                <h2>Üzemeltető</h2>
                <ul>
                    <li>Cégnév: Dentalcooplus Kft</li>
                    <li>Székhely: 9700 Szombathely, Sárvár utca 12.</li>
                    <li>Telephely / Levelezési cím: 9700 Szombathely, Sárvár utca 12.</li>
                    <li>Adószám: 13686282-2-18</li>
                    <li>Cégbejegyzés kelte és száma: 2006-02-27 / 18-09-106700</li>
                    <li>Statisztikai számjel: 13686282-4646-113-18</li>
                    <li>Bejegyző bíróság: Szombathelyi Törvényszék Cégbírósága</li>
                    <li>Adatkezelési nyilvántartási szám:</li>
                    <li>Cégvezető: Kenedli Flórián</li>
                    <li>Elérhetőségek: E-mail: info@dentalcoopplus.hu | Telefon: +36-94-318-590</li>
                    <li>Honlap: https://dentalcoopplus.hu/</li>
                </ul>
            </div>
            <div className='impressum-item'>
                <h2>Tárhelyszolgáltató</h2>
                <ul>
                    <li>Név: Rackhost Zrt.</li>
                    <li>Postai cím / Székhely: 6722 Szeged, Tisza Lajos körút 41.</li>
                    <li>E-mail: info@rackhost.hu</li>
                </ul>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Impressum