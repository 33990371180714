import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaFacebook } from "react-icons/fa";
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router';
import Logo from '../Assets/logo.png';
import { useNavigate} from 'react-router';
import '../css/NavBar.css'

const NavBar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const [isOpen, setIsOpen] = useState(false);
  const navigate=useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="navbar">
      <img src={Logo} alt="Logo" className="logo" onClick={() => navigate('/kezdolap')}/>
      <div className='navbar-webshop'>
        <Link to="/webshop">WebShop</Link>
      </div>
      <button
        className="menu-toggle"
        aria-label={isOpen ? "Close Menu" : "Open Menu"}
        onClick={() => setIsOpen(!isOpen)}
      >
        ☰
      </button>
      <ul
        className={
          isSmallScreen
            ? isOpen
              ? "navbar-items-small visible"
              : "navbar-items-small hidden"
            : "navbar-items"
        }
      >
        <li><Link to="/">Termékek</Link></li>
        <li><Link to="/szolgaltatas">Szolgáltatások</Link></li>
        <li><Link to="/rolunk">Rólunk</Link></li>
        <li><Link to="/kapcsolat">Kapcsolat</Link></li>
        <li><a href="https://www.facebook.com/kft.dentalcoopplus/" target="blank"><FaFacebook className='facebook'/></a></li>
        <li className="nav-info">
          <span>
            <FaPhoneAlt />
            <a href="tel:+3694318590">+36 94 318 590</a>
          </span>
          <span>
            <MdEmail />
            <a href="mailto:info@dentalcoopplus.hu">info@dentalcoopplus.hu</a>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;