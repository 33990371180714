import React from 'react'
import { FaSellsy } from "react-icons/fa";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { FaHandsHelping } from "react-icons/fa";
import '../css/Services_Component.css'


const Services_Component = () => {
  return (
    <div className='services_c'>
        <h1>Szolgáltatások</h1>
        <div className='service-cards'>
            <div className="service-card">
                <FaSellsy />
                <h1>Értékesítés</h1>
                <p>Professzionális és modern megoldásokat kínálunk, minőségi technikai eszközökkel.</p>
            </div>
            <div className="service-card">
                <FaScrewdriverWrench />
                <h1>Szervíz</h1>
                <p>Gyors és precíz szerviz szolgáltatást biztosítunk Magyarország egész területén.</p>
            </div>
            <div className="service-card">
                <TiTick />
                <h1>Kivitelzés</h1>
                <p>Segítséget nyújtunk a rendelők kialakításának kivitelezésében.</p>
            </div>
            <div className="service-card">
                <FaHandsHelping />
                <h1>Márkaszervíz</h1>
                <p>Márkaszerviz szolgáltatást nyújtunk a piacon elismert gyártók termékeire.</p>
            </div>
        </div>
    </div>
  )
}

export default Services_Component