import { motion, useMotionValue,  animate } from "framer-motion";
import { useEffect, useState } from "react";

const Counter = ({ targetNumber }) => {
  const count = useMotionValue(0); // Start from 0 // Ensure whole numbers
  const [displayCount, setDisplayCount] = useState(0);

  useEffect(() => {
    const controls = animate(count, targetNumber, {
      duration: 3, // Animation duration (2 seconds)
      ease: "easeOut",
      onUpdate: (value) => setDisplayCount(Math.floor(value)), // Update state
    });

    return () => controls.stop();
  }, [targetNumber, count]);

  return <motion.span>{displayCount.toLocaleString()}</motion.span>;
};

export default Counter;