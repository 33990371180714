import React from 'react'
import NavBar from '../Components/NavBar';
import '../css/About.css'
import Footer from '../Components/Footer';

const About = () => {
  return (
    <div>
      <NavBar/>
      <div className='about'>
        <div className='about-header'>
          <h3>Az ön elégedettsége a Mi sikerünk.</h3>
          <h1>Rólunk</h1>
        </div>
          <div className='about-cards'>
            <div className="about-card">
              <div className="card-title">
                <h2>Több mint 19 éve a piacon</h2>
              </div>
              <div className="card-desc">
                <p>
                A Dentalcoop Plus Kft. már több mint 19 éve a fogászati berendezések szervizelésének és kereskedelmének megbízható szereplője, tevékenységünk Magyarország egész területére kiterjed.
                </p>
              </div>
            </div>
            <div className="about-card">
                <div className="card-title">
                  <h2>Ügyfél támogatás</h2>
                </div>
              <div className="card-desc">
                <p>
                Ügyfeleinkkel hosszú távú együttműködésre törekszünk, kezdettől fogva segítséget nyújtva a rendelők technikai tervezésében, kivitelezésében, a berendezések beüzemelésében, szervizelésében, finanszírozási kérdésekben és a felmerülő problémák megoldásában.
                </p>
              </div>
            </div>
            <div className="about-card">
              <div className="card-title">
                <h2>Legmagasabb minőség</h2>
              </div>
              <div className="card-desc">
                <p>
                Igyekszünk vevőinknek a legmagasabb minőséget garantálni mind szerviztevékenységünket, mind az értékesített terméket illetően. Több neves külföldi cég termékeit forgalmazzuk, mint például az NSK, Nouvag, Cattani.
                </p>
              </div>
            </div>
            <div className="about-card">
                <div className="card-title">
                  <h2>Szakmai tréningek</h2>
                </div>
              <div className="card-desc">
                <p>
                A Dentalcoop Plus Kft. egyik fő alapelve, hogy naprakészek legyünk mind a szerviztevékenységet mind a kereskedelmi vonalat tekintve. Ennek biztosítása érdekében szervizes kollégáink folyamatosan bővítik tudásukat a neves gyártók által szervezett szakmai tréningeken.
                </p>
              </div>
            </div>
            <div className="about-card">
              <div className="card-title">
                <h2>Cefla S. C.</h2>
              </div>
              <div className="card-desc">
                <p>
                Fő partnerünk a Cefla S. C., Európa legnagyobb fogászati szék gyártója. Tevékenységüket 1932-ben kezdték meg fogászati kezelőegységek gyártásával, de a folyamatos fejlesztéseknek köszönhetően napjainkban már a legfejlettebb technológiák széles palettáját kínálják az orvosok számára, melynek eredménye egy olyan prémiumkategóriás termékcsalád, mely minden igényt kielégít. Legismertebb márkái a Stern Weber, a Myray és a Goldspeed.
                </p>
              </div>
            </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default About